body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rain {
  -webkit-animation: slide-y linear 10s infinite, slide-x ease-in-out 10s infinite; /* Safari 4+ */
  -moz-animation:    slide-y linear 10s infinite, slide-x ease-in-out 10s infinite; /* Fx 5+ */
  -o-animation:      slide-y linear 10s infinite, slide-x ease-in-out 10s infinite; /* Opera 12+ */
  animation:         slide-y linear 10s infinite, slide-x ease-in-out 10s infinite; 
}

@-webkit-keyframes slide-y {
  from { background-position-y: 0px; }
  to { background-position-y: 800px; }
}
@-moz-keyframes    slide-y {
  from { background-position-y: 0px; }
  to { background-position-y: 800px; }
}
@-o-keyframes      slide-y {
  from { background-position-y: 0px; }
  to { background-position-y: 800px; }
}
@keyframes         slide-y {
  from { background-position-y: 0px; }
  to { background-position-y: 800px; }
}

@-webkit-keyframes slide-x {
  0% { background-position-x: 0px; }
  25% { background-position-x: -200px; }
  50% { background-position-x: 0px; }
  75% { background-position-x: 200px; }
  100% { background-position-x: 0px; }
}
@-moz-keyframes  slide-x {
  0% { background-position-x: 0px; }
  25% { background-position-x: -200px; }
  50% { background-position-x: 0px; }
  75% { background-position-x: 200px; }
  100% { background-position-x: 0px; }
}
@-o-keyframes     slide-x {
  0% { background-position-x: 0px; }
  25% { background-position-x: -200px; }
  50% { background-position-x: 0px; }
  75% { background-position-x: 200px; }
  100% { background-position-x: 0px; }
}
@keyframes      slide-x {
  0% { background-position-x: 0px; }
  25% { background-position-x: -200px; }
  50% { background-position-x: 0px; }
  75% { background-position-x: 200px; }
  100% { background-position-x: 0px; }
}